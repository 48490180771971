<template>
    <div :class="['home-ad-type',{blackColor:blackcolor}]">
        <h2>{{typeTitle}}</h2>
        <p>{{typeDetail}}</p>
        <img :src="url" alt="">
    </div>
</template>

<script>
    export default {
        props: {
            'type-title': {
                type: String,
                default: '開屏廣告'
            },
            'type-detail':{
                type:String,
                default:'App開啓後全屏沉浸式展示廣告'
            },
            typeimg:{
                type:String,
                default:'home-1.png'
            },
            blackcolor:{
                type:Boolean,
                default:false
            }
        },
        computed: {
            url() {
                return require(`../assets/img/${this.typeimg}`) 
            }
        },
    }
</script>

<style lang="less" scoped>
    .home-ad-type{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        h2{
            color: #eee;
            text-align: center;
            font-weight: 600;
            font-size: 20px;
            margin-top: 12px;
            // padding:0 12px;
        }
        p{
            color: #eee;
            text-align: center;
            margin-top: 3px;
            font-size: 14px;
            // padding:0 12px;
        }
        img{
            margin-top: -15px;
            width: 113%;
        }
        &.blackColor{
            h2{
                color: #353535;
            }
            p{
                color:#353535;
            }
        }
    }
</style>