<template>
    <div class="home">
        <div class="banner">
            <div class="banner-container">
                <div class="banner-content">
                    <h2>關注成長，發掘價值</h2>
                    <p>
                        世創廣告平台是以推動產品成長，發掘產品價值為主導，同時提供整合互聯網產品和媒體資源等服務，協助開發者挖掘價值。
                    </p>
                    <!-- <p class="mini">
                      平台主要服务于国内的初创互联网产品，助其推广、扩张及规模化成型。媒体服务主要依托于增值运营，深挖生态闭环资源，能够大幅有效的提升资源价值。
                    </p>
                    <p class="mini">
                      平台海外服务可以为国际化产品提供标准化Google等资源对接，帮助其投放、运营及规模化，同时非标准化资源可以根据产品或媒体提供专项服务。
                    </p> -->
                </div>
                <div class="banner-bg">
                    <img src="../assets/img/home-bg.png" alt="bg">
                    <div class="ani-box">
                        <div>
                            <img src="../assets/img/anm1.png" alt="ss">
                        </div>
                        <div>
                            <img src="../assets/img/anm2.png" alt="ss">
                        </div>
                        <div>
                            <img src="../assets/img/anm3.png" alt="ss">
                        </div>
                        <div>
                            <img src="../assets/img/anm1.png" alt="ss">
                        </div>
                        <div>
                            <img src="../assets/img/anm2.png" alt="ss">
                        </div>
                        <div>
                            <img src="../assets/img/anm3.png" alt="ss">
                        </div><!--其中一组1,2,3用于固定div盒子圆的大小-->
                        <i></i><i></i><i></i>
                    </div>
                    <div class="river">
                        <img v-for='item in 2' :key='item' src="../assets/img/river.png" alt="">
                    </div>
                    <img src="../assets/img/jb.png" alt="">
                    <img src="../assets/img/blx.png" alt="#">
                </div>
            </div>
        </div>
        <div class="ad-ability">
            <div class="ability-title">
                <h2>多維的平台服務</h2>
                <h6>世創平台目前四大核心：小程序插件、聚合SDK、H5、海外流量變現的簡介及價值</h6>
                <div>
                    <img src="../assets/img/bottom.png" alt="">
                </div>
            </div>
            <div class="ability-container">
                <div :class="['ability-detail',{animate:scrollAtHere}]">
                    <div>
                        <div>
                            <img src="../assets/img/xcxcj.png" alt="">
                        </div>
                        <h5>小程序插件<span></span></h5>
                        <p>
                            針對微信小程序廣告接入服務的一款自研插件，已通過微信平台審核上線。完全支持騰訊官方小程序廣告接入，由騰訊雲支持，流量安全性強。支持小程序流量植入、整形，流量真實性高，部分服務內容支持h5廣告落地頁或小程序原生頁面落地頁廣告推廣及產品接入。</p>
                    </div>
                    <div>
                        <div>
                            <img src="../assets/img/jhsdk.png" alt="">
                        </div>
                        <h5>聚合SDK<span></span></h5>
                        <p>整合了國內95%以上的一線sdk服務，實現資源互補，併為不同產品提供不同的對接方案幫助其獲取穩定的廣告預算資源。自有運營體系能夠很好的實現流量的價值提升。</p>
                    </div>
                    <div>
                        <div>
                            <img src="../assets/img/h5.png" alt="">
                        </div>
                        <h5>H5<span></span></h5>
                        <p>基於垂直行業的wap增值變現及定製化流量服務。</p>
                    </div>
                    <div>
                        <div>
                            <img src="../assets/img/hw.png" alt="">
                        </div>
                        <h5>海外流量<span></span></h5>
                        <p>標準化平台服務開户，產品推廣，運營投放；非標準化資源轉換及變現。</p>
                    </div>
                </div>
            </div>
        </div>
        <div :class="['efficient',{arrive:arriveEfficient}]">
            <h2 :class="{arrive:arriveEfficient}">高效的平台能力</h2>
            <div :class="['efficient-container',{arrive:arriveEfficient}]">
                <div>
                    <img src="../assets/img/power.png" alt="">
                </div>
                <div>
                    <h5>專業</h5>
                    <h4></h4>
                    <p>平台提供真正的全流程服務</p>
                    <h5>創新</h5>
                    <h4></h4>
                    <p>自主研發的廣告系統及運營體系</p>
                    <h5>高值</h5>
                    <h4></h4>
                    <p>增值價值普遍高於市場20%-30%</p>
                    <!-- <div>
                      <span>了解更多</span>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="ad-type">
            <h2>豐富的廣告場景</h2>
            <div class="type-container">
                <div class="img-container">
                    <img src="../assets/img/scene_bg.png" alt="">
                </div>
                <div class="type-content">
                    <div :style="{'top':typesPosition.type1}">
                        <HomeAdStyle
                                type-title='Banner廣告'
                                type-detail='在App頂/中/底部懸浮且可關閉的邊欄廣告'
                        />
                    </div>
                    <div :style="{'top':typesPosition.type2}">
                        <HomeAdStyle
                                blackcolor
                                typeimg='home-2.png'
                        />
                        <HomeAdStyle
                                type-title='動態信息流'
                                type-detail='廣告與App內容高度自然融合的信息流'
                                typeimg='home-3.png'
                                class="imgReset"
                        />
                    </div>
                    <div :style="{'top':typesPosition.type3}">
                        <HomeAdStyle
                                type-title='激勵視頻廣告'
                                typeimg='home-4.png'
                                type-detail='完整觀看視頻廣告以免費獲得遊戲獎勵'
                        />
                    </div>
                    <div :style="{'top':typesPosition.type4}">
                        <HomeAdStyle
                                type-title='插屏廣告'
                                typeimg='home-5.png'
                                type-detail='在彈出窗口上展示廣告'
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel">
            <h2>精選合作案例</h2>
            <Carousel/>
        </div>
        <div class="partner">
            <h2>主要合作伙伴</h2>
            <div>
                <img src="../assets/img/pt_tt.png" alt="">
                <img src="../assets/img/pt_tx.png" alt="">
                <img src="../assets/img/pt_bd.png" alt="">
                <img src="../assets/img/pt_dy.png" alt="">
                <img src="../assets/img/pt_jd.png" alt="">
                <img src="../assets/img/pt_mp.png" alt="">
                <img src="../assets/img/pt_qczj.png" alt="">
                <img src="../assets/img/pt_tb.png" alt="">
                <img src="../assets/img/pt_tq.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import HomeAdStyle from '../components/HomeAdType.vue'
    import Carousel from '../components/Carousel.vue'

    export default {
        components: {
            HomeAdStyle, Carousel
        },
        data() {
            return {
                scrollTop: 0,
                scrollHelpNum: 0,
                baseScrollPosi: 0,
                scrollAtHere: false,//控制‘多维网站能力’部分icon图标转动
                arriveEfficient: false
            }
        },
        computed: {
            typesPosition() {
                let {baseScrollPosi} = this
                let
                    type1 = baseScrollPosi + 160 + 'px',
                    type2 = baseScrollPosi - 158 + 'px',
                    type3 = baseScrollPosi + 76 + 'px',
                    type4 = baseScrollPosi + 225 + 'px'
                return {
                    type1, type2, type3, type4
                }
            }
        },
        watch: {
            scrollTop(newValue, oldValue) {//监控滚动条的位置，实现首页‘广告样式’部分的微滑动效果
                if (this.scrollHelpNum === 0) {
                    this.scrollHelpNum = 1
                    let timer = setTimeout(() => {//用一次性定时器做了一个节流
                        this.scrollHelpNum = 0
                        clearTimeout(timer)
                    }, 100)
                    this.baseScrollPosi = -130 * (this.scrollTop - 1000) / 2500
                }
                if (newValue > 1050 && newValue < 2200) {
                    this.arriveEfficient = true
                } else {
                    this.arriveEfficient = false
                }
            }
        },
        methods: {
            handleScroll() {
                let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                // console.log(scrollTop)
                if (scrollTop >= 1000 && scrollTop <= 3700) {
                    this.scrollTop = scrollTop
                }
                if (scrollTop >= 500 && scrollTop <= 1200) {
                    this.scrollAtHere = true
                } else {
                    this.scrollAtHere = false
                }
            }
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll, true)
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll, true)
        }
    }
</script>
<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');

    .home {
        .banner {
            overflow: hidden;
            height: 720px;

            .banner-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 1200px;
                height: 100%;
                margin: 0 auto;

                .banner-content {
                    width: 40%;
                    overflow: hidden;

                    h2 {
                        font-size: 50px;
                        font-weight: 600;
                    }

                    p {
                        font-size: 16px;
                        margin-top: 30px;
                        color: #535353;

                        &.mini {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 10px;
                            font-size: 14px;
                            height: 60px;
                            margin-right: 30px;
                            border: 1px solid @themeColor;
                            border-radius: 6px;
                            background: none;
                            color: @themeColor;
                        }
                    }
                }

                .banner-bg {
                    width: 50%;
                    height: 100%;
                    position: relative;

                    > img:nth-child(1) {
                        position: absolute;
                        right: -400px;
                        top: 60px;
                        width: 200%;
                    }

                    > img:nth-last-of-type(2) {
                        position: absolute;
                        right: 448px;
                        top: 344px;
                        width: 103px;
                        z-index: 100;
                    }

                    > img:last-child {
                        position: absolute;
                        right: -190px;
                        top: 310px;
                        width: 710px;
                        height: 80px;
                        transform: rotateZ(-5deg);
                    }

                    > .ani-box {
                        position: absolute;
                        top: -21px;
                        left: 129px;
                        width: 100%;
                        height: 100%;

                        > div {
                            position: absolute;
                            padding: 8px;
                            border-radius: 50%;
                            border: 1px solid @themeColor;

                            img {
                                width: 60px;
                            }

                            &:nth-of-type(1) {
                                top: 160px;
                                right: 60px;
                                border: none;
                                animation: anm1Move 16s linear 2s infinite alternate;
                                z-index: 10;
                            }

                            &:nth-of-type(2) {
                                top: 260px;
                                right: 160px;
                                border: none;
                                animation: anm2Move 16s linear 2s infinite alternate;
                                z-index: 10;
                            }

                            &:nth-of-type(3) {
                                top: 180px;
                                right: 460px;
                                border: none;
                                animation: anm3Move 16s linear 2s infinite alternate;
                                z-index: 10;
                            }

                            &:nth-of-type(4) {
                                top: 160px;
                                right: 60px;
                                box-shadow: 4px 4px 14px @themeColor;

                                img {
                                    opacity: 0;
                                }
                            }

                            &:nth-of-type(5) {
                                top: 260px;
                                right: 160px;

                                img {
                                    opacity: 0;
                                }

                                box-shadow: 4px 4px 14px @themeColor;
                            }

                            &:nth-of-type(6) {
                                top: 180px;
                                right: 460px;

                                img {
                                    opacity: 0;
                                }

                                box-shadow: 4px 4px 14px @themeColor;
                            }
                        }

                        i {
                            position: absolute;
                            border-top: 1px solid @themeColor;

                            &:nth-of-type(1) {
                                width: 150px;
                                top: 267px;
                                right: 42px;
                                transform: rotateZ(-47deg);
                            }

                            &:nth-of-type(2) {
                                width: 396px;
                                top: 170px;
                                right: 96px;
                                transform: rotateZ(-3deg);
                            }

                            &:nth-of-type(3) {
                                width: 310px;
                                top: 297px;
                                right: 199px;
                                transform: rotateZ(15deg);
                            }
                        }
                    }

                    > .river {
                        width: 750px;
                        height: 152px;
                        position: relative;
                        top: 300px;

                        img {
                            position: absolute;
                            width: 60px;

                            &:nth-of-type(odd) {
                                top: 88px;
                                left: 20px;
                                animation: river1Move 16s linear 1s infinite;
                            }

                            &:nth-of-type(even) {
                                top: 138px;
                                left: 20px;
                                animation: river2Move 16s linear 1s infinite;
                            }
                        }
                    }
                }
            }
        }

        .ad-ability {
            background: white;

            .ability-title {
                width: 100%;
                background: @themeColor;
                padding: 80px 0;

                h2 {
                    font-weight: 600;
                    font-size: 38px;
                    text-align: center;
                    color: white;
                }

                h6 {
                    margin: 26px 0;
                    text-align: center;
                    font-size: 20px;
                    color: white;
                }

                div {
                    text-align: center;

                    img {
                        width: 36px;
                    }
                }
            }

            .ability-container {
                width: 1200px;
                margin: 0 auto;
                padding: 80px 0 160px;

                .ability-detail {
                    display: flex;
                    justify-content: space-between;

                    &.animate {
                        > div {
                            &:nth-child(1) {
                                > div {
                                    animation: divRotate 2s linear 1s;
                                }
                            }

                            &:nth-child(2) {
                                > div {
                                    animation: divRotate 2s linear 3s;
                                }
                            }

                            &:nth-child(3) {
                                > div {
                                    animation: divRotate 2s linear 5s;
                                }
                            }

                            &:last-child {
                                > div {
                                    animation: divRotate 2s linear 7s;
                                }
                            }
                        }
                    }

                    > div {
                        width: 23%;

                        p {
                            color: #989898 !important;
                            text-align: center;
                        }

                        > div {
                            img {
                                width: 58px;
                            }
                        }

                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                        margin-top: 20px;
                        margin-right: 36px;

                        &:last-child {
                            margin-right: 0;
                        }

                        h5 {
                            font-weight: 600;
                            font-size: 24px;
                            margin: 36px 0 26px;
                        }

                        span {
                            position: absolute;
                            top: 146px;
                            left: 116px;
                            width: 40px;
                            height: 2px;
                            background: @themeColor;
                        }

                        p {
                            margin-top: 20px;
                            color: #797979;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .efficient {
            height: 800px;
            background: white;
            padding-top: 80px;
            transition: 1.3s;

            &.arrive {
                background: #f7f8f9;
            }

            h2 {
                font-weight: 600;
                font-size: 38px;
                text-align: center;
                padding: 30px 0 80px;
                opacity: 0;
                transition: .9s;

                &.arrive {
                    opacity: 1;
                }
            }

            .efficient-container {
                width: 1200px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;

                > div {
                    width: 46%;
                    transition: .9s;

                    > img {
                        width: 80%;
                    }

                    h5 {
                        font-size: 24px;
                        font-weight: 600;
                        margin-top: 30px;
                    }

                    h4 {
                        width: 36px;
                        height: 2px;
                        background: @themeColor;
                        margin: 16px 0;
                    }

                    p {
                        color: #777;
                    }

                    > div {
                        margin-top: 60px;
                        cursor: pointer;
                        width: 150px;
                        height: 56px;
                        border: 2px solid @themeColor;
                        border-radius: 28px;
                        text-align: center;
                        transition: .3s;

                        span {
                            font-size: 24px;
                            color: @themeColor;
                            line-height: 52px;
                            transition: .3s;
                        }

                        &:hover {
                            background: @themeColor;

                            span {
                                color: white;
                            }
                        }
                    }

                    &:nth-child(1) {
                        opacity: 0;
                        transform: translate(-100px, 10px);
                    }

                    &:nth-child(2) {
                        opacity: 0;
                        transform: translate(100px, 10px);
                    }
                }

                &.arrive {
                    > div {
                        &:nth-child(1) {
                            opacity: 1;
                            transform: translate(0px, 0px);
                        }

                        &:nth-child(2) {
                            opacity: 1;
                            transform: translate(0px, 0px);
                        }
                    }
                }
            }
        }

        .ad-type {
            padding-bottom: 420px;

            h2 {
                font-weight: 600;
                font-size: 38px;
                text-align: center;
                margin: 80px 0 240px;
            }

            .type-container {
                height: 635px;
                position: relative;
                background: rgba(132, 146, 223, .6);

                .img-container {
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    > img {
                        position: absolute;
                        top: 0;
                        left: -10px;
                        width: 105%;
                        height: 100%;
                        opacity: .8;
                    }
                }

                .type-content {
                    width: 1200px;
                    height: 635px;
                    margin: 0 auto;
                    position: relative;

                    > div {
                        width: 25%;
                        position: absolute;

                        &:nth-child(1) {
                            transition: .1s;
                            left: -14px;
                        }

                        &:nth-child(2) {
                            height: 1200px;
                            left: 293px;

                            /deep/ .home-ad-type.imgReset {
                                img {
                                    margin-top: 10px;
                                    width: 103%;
                                }
                            }

                            transition: .3s;
                        }

                        &:nth-child(3) {
                            left: 597px;
                            transition: .3s;
                        }

                        &:nth-child(4) {
                            left: 904px;
                            transition: .3s;
                        }
                    }
                }
            }

        }

        .carousel {
            width: 100%;
            background: #f7f8f9;
            padding-bottom: 200px;

            h2 {
                font-weight: 600;
                font-size: 36px;
                text-align: center;
                padding: 100px;
            }
        }

        .partner {
            height: 600px;

            h2 {
                font-weight: 600;
                font-size: 38px;
                text-align: center;
                padding: 80px 0;
                margin-top: 30px;
            }

            div {
                display: flex;
                justify-content: center;

                img {
                    width: 80px;
                    margin: 30px;
                }
            }
        }
    }

    @keyframes anm1Move {
        0% {
            top: 160px;
            right: 60px;
        }
        33% {
            top: 260px;
            right: 160px;
        }
        66% {
            top: 180px;
            right: 460px;
        }
        100% {
            top: 160px;
            right: 60px;
        }
    }

    @keyframes anm2Move {
        0% {
            top: 260px;
            right: 160px;
        }
        33% {
            top: 180px;
            right: 460px;
        }
        66% {
            top: 160px;
            right: 60px;
        }
        100% {
            top: 260px;
            right: 160px;
        }
    }

    @keyframes anm3Move {
        0% {
            top: 180px;
            right: 460px;
        }
        33% {
            top: 160px;
            right: 60px;
        }
        66% {
            top: 260px;
            right: 160px;
        }
        100% {
            top: 180px;
            right: 460px;
        }
    }

    @keyframes river1Move {
        0% {
            top: 88px;
            left: 20px;
        }
        25% {
            top: 88px;
            left: 160px;
        }
        50% {
            top: 88px;
            left: 320px;
        }
        100% {
            top: 88px;
            left: 680px;
        }
    }

    @keyframes river2Move {
        0% {
            top: 138px;
            left: 20px;
        }
        25% {
            top: 116px;
            left: 160px;
        }
        50% {
            top: 102px;
            left: 320px;
        }
        100% {
            top: 102px;
            left: 680px;
        }
    }

    @keyframes divRotate {
        0% {
            transform: rotateY(0deg);
        }
        50% {
            transform: rotateY(180deg);
        }
        100% {
            transform: rotateY(360deg);
        }
    }
</style>