import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import defaultSetting from '../setting'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: '首頁'
    },
    {
        path: '/adstyle',
        name: 'AdStyle',
        component: () => import('../views/AdStyle.vue'),
        meta: '广告形态'
    },
    {
        path: '/sdk',
        name: 'SDK',
        component: () => import('../views/SDK.vue'),
        meta: '扬歌SDK'
    }, {
        path: '/dynamic',
        name: 'Dynamic',
        component: () => import('../views/Dynamic.vue'),
        meta: '關於我們'
    }, {
        path: '/service',
        name: 'Service',
        component: () => import('../views/Service.vue'),
        meta: '服务流程'
    }, {
        path: '/helpcenter',
        name: 'HelpCenter',
        component: () => import('../views/HelpCenter.vue'),
        meta: '帮助中心'
    }, {
        path: '/helpcenterdetail',
        name: 'HelpCenterDetail',
        component: () => import('../views/HelpCenterDetail.vue'),
        meta: '帮助中心'
    }, {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue'),
        meta: '聯繫我們'
    }
]

const router = new VueRouter({
    // history: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta) {
        document.title = `${defaultSetting.title} - ${to.meta}`
    } else {
        document.title = `${defaultSetting.title}`
    }
    next()
})

export default router
